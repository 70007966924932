import Vue from "vue";
import Router from "vue-router";

// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");
const Orders = () => import("@/views/Orders");

// Machine
const Machines = () => import("@/views/machines/Machines");
const NewMachine = () => import("@/views/machines/NewMachine");
const SimManager = () => import("@/views/machines/SimManager");
const Setting = () => import("@/views/machines/Setting");
const EventMachine = () => import("@/views/machines/EventMachine");
const Commands = () => import("@/views/machines/Commands");
const ProblemReport = () => import("@/views/machines/ProblemReport");

// Products
const Products = () => import("@/views/products/Products");
const NewProduct = () => import("@/views/products/NewProduct");
const Categories = () => import("@/views/products/Categories");

const Devices = () => import("@/views/Devices");

// Report
const Reports = () => import("@/views/reports/Reports");
const SalesByDealer = () => import("@/views/reports/SalesByDealer");
const SalesByUser = () => import("@/views/reports/SalesByUser");
const SalesByMachine = () => import("@/views/reports/SalesByMachine");
const ClearMoneyReport = () => import("@/views/reports/ClearMoneyReport");
const SMSreport = () => import("@/views/reports/SMSreport");
const UserReport = () => import("@/views/reports/UserReport");
const IncompleteReport = () => import("@/views/reports/IncompleteReport");
const MaintenanceHistoryReport = () => import("@/views/reports/MaintenanceHistoryReport");
const MaintenanceRemainderReport = () => import("@/views/reports/MaintenanceRemainderReport");
const ElectricityProblemReport = () => import("@/views/reports/ElectricityProblemReport");
const CreditRefundReport = () => import("@/views/reports/CreditRefundReport");
const OnlinePayment = () => import("@/views/reports/OnlinePayment");
const DeviceRevenue = () => import("@/views/reports/DeviceRevenue");


// Pages
const Login = () => import("@/components/login/login");
const Home = () => import("@/views/Home");

// Users
const Users = () => import("@/views/users/Users");
const NewUser = () => import("@/views/users/NewUser");
const CustomerCredit = () => import("@/views/users/CustomerCredit");
const CustomerCreditDetail = () => import("@/views/users/CustomerCreditDetail");

// Promotion
const Promotion = () => import("@/views/promotion/Promotion");
const EditPromotion = () => import("@/views/promotion/EditPromotion");
const CreatePromotion = () => import("@/views/promotion/CreatePromotion");

// Analyze
const SalesByMonth = () => import('@/views/analyze/SalesByMonth');
const SalesByDayName = () => import('@/views/analyze/SalesByDayName');
const CustomerByDay = () => import('@/views/analyze/CustomerByDay');
const TurnPerDay = () => import('@/views/analyze/TurnPerDay');
const RatingUse = () => import('@/views/analyze/RatingUse');
const UsageFrequency = () => import('@/views/analyze/UsageFrequency');
const Monthly = () => import('@/views/analyze/Monthly');
const CustomerDetails = () => import('@/views/analyze/CustomerDetails');

const SalesByDate = () => import('@/views/analyze/SalesByDate');
const CustomerByDate = () => import('@/views/analyze/CustomerByDate');
const SalesEstimate = () => import('@/views/analyze/SalesEstimate');

// Tax Report
const CashReceiptReport = () => import('@/views/tax_report/CashReceiptReport.vue');
const CustomerCreditReport = () => import('@/views/tax_report/CustomerCreditReport.vue');
const PersonalTaxReport = () => import('@/views/tax_report/PersonalTaxReport.vue');

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/home",
      name: "Home",
      component: DefaultContainer,
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard
        },
        {
          path: "/orders",
          name: "Orders",
          component: Orders
        },
        {
          path: "/machine",
          redirect: "/machine/machines",
          name: "Machine",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/machine/machines",
              name: "Machines",
              component: Machines
            },
            {
              path: "/machine/setting/:id",
              name: "Setting",
              component: Setting,
              props: true
            },
            {
              path: "/machine/new_machine",
              name: "New Machine",
              component: NewMachine
            },
            {
              path: "/machine/event_machine",
              name: "Event Machine",
              component: EventMachine
            },
            {
              path: "/machine/commands",
              name: "Commands",
              component: Commands
            },
            {
              path: "/machine/problemreport",
              name: "Problem Report",
              component: ProblemReport
            }
          ]
        },
        {
          path: "/sim",
          redirect: "/sim/sims",
          name: "Sims",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/sim/sims",
              name: "Sim Manager",
              component: SimManager
            }
          ]
        },
        {
          path: "/product",
          redirect: "/product/list",
          name: "Product",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/product/list",
              name: "List",
              component: Products
            },
            {
              path: "/product/create",
              name: "Create",
              component: NewProduct
            },
            {
              path: "/product/:mode/:productid",
              name: "Edit",
              component: NewProduct
            },
            {
              path: "categories",
              name: "Categories",
              component: Categories
            }
          ]
        },
        {
          path: "user",
          redirect: "/user/list",
          name: "User",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/user/list",
              name: "User List",
              component: Users
            },
            {
              path: "/user/create",
              name: "Create User",
              component: NewUser
            },
            {
              path: "/user/:mode/:userId",
              name: "Edit User",
              component: NewUser
            },
            // {
            //   path: "/user/customercredit",
            //   name: "Customer Credit",
            //   component: CustomerCredit
            // },
            // {
            //   path: "/customercredit/detail",
            //   name: "Customer Credit Detail",
            //   component: CustomerCreditDetail
            // }
          ]
        },
        {
          path: "customer Credit",
          redirect: "/customercredit",
          name: "customercredit",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/customercredit/all",
              name: "Customer Credit",
              component: CustomerCredit
            },
            {
              path: "/customercredit/detail",
              name: "Customer Credit Detail",
              component: CustomerCreditDetail
            }
          ]
        },
        {
          path: "/devices",
          name: "Devices",
          component: Devices
        },
        {
          path: "report",
          redirect: "/report/reports",
          name: "Report",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/report/reports",
              name: "Reports",
              component: Reports
            },
            {
              path: "/report/sales_by_dealer",
              name: "Sales by Dealer",
              component: SalesByDealer
            },
            {
              path: "/report/sales_to_income",
              name: "Sales To Income",
              component: SalesByUser
            },
            {
              path: "/report/sales_by_machine",
              name: "Sales by Machine",
              component: SalesByMachine
            },
            {
              path: "/report/clear_inbox_report",
              name: "Clear Inbox Report",
              component: ClearMoneyReport
            },
            {
              path: "/report/sms_report",
              name: "SMS Report",
              component: SMSreport
            },
            {
              path: "/report/user_report",
              name: "User Report",
              component: UserReport
            },
            {
              path: "/report/incomplete_report",
              name: "Incomplete Report",
              component: IncompleteReport
            },
            {
              path: "/report/maintenance_history_report",
              name: "Maintenance History Report",
              component: MaintenanceHistoryReport
            },
            {
              path: "/report/maintenance_remainder_report",
              name: "Maintenance Remainder Report",
              component: MaintenanceRemainderReport
            },
            {
              path: "/report/electricity_problem_report",
              name: "Electricity Problem Report",
              component: ElectricityProblemReport
            },
            {
              path: "/report/credit_refund_report",
              name: "Credit Refund Report",
              component: CreditRefundReport
            },
            {
              path: "/report/online_payment",
              name: "Online Payment",
              component: OnlinePayment
            },
            {
              path: "/report/device_revenue",
              name: "Revenue Per Machines",
              component: DeviceRevenue
            }
          ]
        },
        {
          path: "/promotion",
          name: "Promotion",
          redirect: "/promotion/all",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/promotion/all",
              name: "Promotions",
              component: Promotion
            },
            {
              path: "/promotion/edit/:machineId/:promotionId",
              name: "EditPromotion",
              component: EditPromotion
            },
            {
              path: "/promotion/create",
              name: "CreatePromotion",
              component: CreatePromotion
            }
          ]
        },
        {
          path: "/tax_report",
          name: "Tax Report",
          redirect: "/tax_report/all",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/tax_report/cash_receipt_report",
              name: "Cash Receipt Report",
              component: CashReceiptReport
            },
            {
              path: "/tax_report/customer_credit_report",
              name: "Customer Credit Report",
              component: CustomerCreditReport
            },
            {
              path: "/tax_report/personal_tax_report",
              name: "Personal Tax Report",
              component: PersonalTaxReport,
            }
          ]
        },
        {
          path: "/analyze",
          name: "Analyze",
          redirect: "/analyze/all",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/analyze/sales_by_month",
              name: "SalesByMonth",
              component: SalesByMonth
            },
            {
              path: "/analyze/sales_by_dayname",
              name: "Sales By Day Name",
              component: SalesByDayName
            },
            {
              path: "/analyze/customer_by_day",
              name: "Customer By Day",
              component: CustomerByDay
            },
            {
              path: "/analyze/turn_per_day",
              name: "Turn Per Day",
              component: TurnPerDay
            },
            {
              path: "/analyze/rating_use",
              name: "Rating Use",
              component: RatingUse
            },
            {
              path: "/analyze/usage_frequency",
              name: "Usage Frequency",
              component: UsageFrequency
            },
            {
              path: "/analyze/monthly",
              name: "Monthly",
              component: Monthly
            },
            {
              path: "/analyze/customerdetails",
              name: "CustomerDetails",
              component: CustomerDetails
            },
            {
              path: "/analyze/sales_by_date",
              name: "SalesByDate",
              component: SalesByDate
            },
            {
              path: "/analyze/customer_by_date",
              name: "CustoemrByDate",
              component: CustomerByDate
            },
            {
              path: "/analyze/salesestimate",
              name: "SalesEstimate",
              component: SalesEstimate
            },

          ]
        },

      ]
    },
    {
      path: "/",
      name: "",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: Login
        },
        {
          path: "home",
          name: "home",
          component: Home
        }
      ]
    }
  ]
});
