export const appVersion = process.env.VUE_APP_VERSION || "DEV";

// export const DOMAIN = "http://localhost:3000/api";
export const DOMAIN = GetDomain();
export const APIENDPOINT = DOMAIN + "/api/v1/web";

export const getHeader = function () {
  const headers = {
    "Content-Type": "application/json",
  };
  return headers;
};

export const getHeaderUser = function () {
  const tokenData = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + tokenData,
  };
  return headers;
};

function GetDomain() {
  if (process.env.NODE_ENV === "production") {
    return "" //'VUE_APP_API_APIENDPOINT'
  } else {
    // return process.env.VUE_APP_API_APIENDPOINT || "http://localhost:3000";
    return "http://localhost:3000" || process.env.VUE_APP_API_APIENDPOINT;
  }
}
