import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
    isLogin: localStorage.getItem('isLogin'),
    nav: [],
    users : {}
  }
})
