<template>
  <div id="app">
    <meta charset="UTF-8" />
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted() {
    if (localStorage.getItem('isLogin')) {
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

      this.axios
        .get('/user')
        .then(res => {
          console.log('Get user success, App')
          let lang = window.localStorage.getItem('language')
          if (!lang) {
            lang = 'th'
            window.localStorage.setItem('language', lang)
            this.$i18n.locale = lang
          } else {
            this.$i18n.locale = lang
          }
          this.$store.state.nav = this.$t('message.nav')
          this.$store.state.users = res.data
          // window.localStorage.setItem('users', res.data)
        })
        .catch(function (err) {
          // alert('หมดเวลาการเชื่อมต่อ กรุณาเข้าสู่ระบบใหม่อีกครั้ง')
          localStorage.clear()
          window.location.reload()
          console.log('Cant get user, App ' + err)
        })
    } else {
      console.log('Please login, APP')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss">
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.min.css';
/* Import Font Awesome Icons Set */
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';
/* Import Simple Line Icons Set */
$simple-line-font-path: '~simple-line-icons/fonts/';
@import '~simple-line-icons/scss/simple-line-icons.scss';
/* Import Flag Icons Set */
@import '~flag-icon-css/css/flag-icon.min.css';
/* Import Bootstrap Vue Styles */
@import '~bootstrap-vue/dist/bootstrap-vue.css';
// Import Main styles for this application
@import 'assets/scss/style';
// Import sweetalert2
@import '~sweetalert2/dist/sweetalert2.min.css';


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .col-sdx-12  {
    width: 100%;
    margin-left: 5%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .col-sdx-12  {
    width: 100%;
    margin-left: 5%;}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .col-mdx-3  {width: 280px; margin-left: 2%;}
  .col-mdx-2  {width: 48%; margin-left: 2%;}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .col-lgx-2  {width: 340px; margin-left: 2%;}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .col-xlx-3  {width: 280px; margin-left: 2%;}
  .col-xlx-4  {width: 240px; margin-left: 2%;}
}

</style>
